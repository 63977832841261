@mixin centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quick-guide {
  box-shadow:  0 .5rem 1.5rem 0.5rem rgba(#000000, .075);
  border-radius: 5px;
  background: white;
  margin-top: -2rem;
}

.quick-guide__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quick-guide__content-wrapper {
  display: flex;
  align-items: center;
}

.quick-guide__icon-wrapper {
  position: relative;
  width: 70px;
  height: 70px;
}

.quick-guide__icon-text {
  @include centerAbsolute;
  font-size: 30px;
  font-family: 'Arial, sans-serif';
  color: #3699FF;
  font-weight: bold;
}

.quick-guide__close-icon  {
  border: none;
  background: transparent;
  cursor: pointer;
}