// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_ens/_assets/sass/style.react.scss';

// Default Layout themes
@import './_ens/_assets/sass/themes/layout/header/base/light.scss';
@import './_ens/_assets/sass/themes/layout/header/menu/light.scss';
@import './_ens/_assets/sass/themes/layout/brand/dark.scss';
@import './_ens/_assets/sass/themes/layout/aside/dark.scss';

// Brand themes
.brand-dark {
  @import './_ens/_assets/sass/themes/layout/brand/dark.scss';
}

// Light
.brand-light {
  @import './_ens/_assets/sass/themes/layout/brand/light.scss';
}


//Custom Style
@import '_ens/_assets/sass/layout/quick-guide';



// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

@import url("tldraw/tldraw.css");


.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.confiormation-center {
  width: 100%;
  position: relative;
  display: block;
}

.confiormation-text {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.autocomplete-dropdown-container {
  width: 100%;
  border: 1px solid #edf1f5;
  font-size: 15px;
  line-height: 20px;
  border-radius: 4px;
}

.autocomplete-dropdown-container div {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 15px;
  border-bottom: 1px solid #edf1f5;
}

.btn.btn-delete {
  color: #ffffff !important;
  background-color: #f94b4b !important;
  border-color: #f3f6f9 !important;
}

.interpiter_menu > div:nth-child(3) {
  z-index: 99 !important;
}

.avviso_sospensione {
  text-align: center;
  padding: 20px;
  background-color: #f4bdbd;
  border-left: 5px solid #F00;

  strong {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .login.login-1 {
    .login-aside {
      position: unset;
      height: 50vh;
      padding: 10px !important;

      .justify-content-between {
        text-align: center;

        img {
          width: 150px !important;
        }
      }
    }

    .authentication__wrapper {
      margin-top: 0 !important;
    }

    .registration__wrapper {
      position: unset !important;
      text-align: center !important;
      margin-bottom: 20px !important;
    }
  }
}

//video call css
.vc_title {
  padding: 15px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.vc_title h3 {
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}

.vc_waiting_user {
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
}

.vc_waiting_animation {
  width: 250px;
  height: 250px;
  position: relative;
  margin: 0 auto;
}

.vc_waiting_main_circle {
  width: calc(100% - 90px);
  height: calc(100% - 90px);
  background-color: #3699ff;
  animation: vc_animate 1.2s infinite alternate ease-in;
  -webkit-animation: vc_animate 1.2s infinite alternate ease-in;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.vc_circle_wave {
  width: 100%;
  height: 100%;
  background-color: #3699ff;
  animation: scaleIn 4s infinite ease-in forwards;
  -webkit-animation: scaleIn 4s infinite ease-in forwards;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.vc_circle_wave_green {
  background-color: #18be09 !important;
}

@keyframes scaleIn {
  from {
    opacity: 0.5;
    transform: scale(0.5, 0.5);
    -webkit-transform: scale(0.5, 0.5);
    -moz-transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -o-transform: scale(0.5, 0.5);
  }
  to {
    opacity: 0;
    transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
  }
}

@-o-keyframes scaleIn {
  from {
    opacity: 0.5;
    transform: scale(0.5, 0.5);
    -webkit-transform: scale(0.5, 0.5);
    -moz-transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -o-transform: scale(0.5, 0.5);
  }
  to {
    opacity: 0;
    transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
  }
}

@-moz-keyframes scaleIn {
  from {
    opacity: 0.5;
    transform: scale(0.5, 0.5);
    -webkit-transform: scale(0.5, 0.5);
    -moz-transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -o-transform: scale(0.5, 0.5);
  }
  to {
    opacity: 0;
    transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
  }
}

@-webkit-keyframes scaleIn {
  from {
    opacity: 0.5;
    transform: scale(0.5, 0.5);
    -webkit-transform: scale(0.5, 0.5);
    -moz-transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -o-transform: scale(0.5, 0.5);
  }
  to {
    opacity: 0;
    transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
  }
}

.vc_circle_wave.wave_1 {
  animation-delay: -3s;
}

.vc_circle_wave.wave_2 {
  animation-delay: -2s;
}

.vc_circle_wave.wave_3 {
  animation-delay: -1s;
}

.vc_circle_wave.wave_4 {
  animation-delay: 0s;
}

.vc_waiting_text {
  text-align: center;
  padding-top: 40px;
}

.vc_waiting_text p {
  margin: 0;
  font-size: 24px;
}

.vc_waiting_btn.global_btn {
  text-align: center;
}

.global_btn {
  padding-top: 30px;
}

.global_btn button {
  background-color: #3699ff;
  color: #fff;
  border: 0;
  padding: 12px 30px;
  font-size: 18px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.vc_serve_next_user {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vc_next_user_icon {
  text-align: center;
}

.vc_next_user_icon svg {
  max-width: 300px;
}

.vc_main_screen {
  height: calc(100vh - 150px);
  position: relative;
}

.vc_main_screen_video {
  height: 100%;
}

.vc_main_screen_video video {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-color: #000;
}

svg:focus {
  outline: none !important;
}

@media (max-width: 1920px) {
  .vc_waiting_user,
  .vc_serve_next_user,
  .vc_main_screen {
    height: calc(100vh - 250px);
  }
}

@media (max-width: 1500px) {
  .vc_main_screen,
  .vc_serve_next_user {
    height: calc(100vh - 230px);
  }

  .vc_waiting_user {
    height: calc(100vh - 240px);
  }
}

.video-call-box {
  position: relative;
}

.video-call-box.fs {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999;
  background-color: #000;
}

.video-call-box #remote-video-container {
  width: 100%;
  // height: auto;
  height: 90%;
}

.video-call-box #remote-video-container video {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.video-call-box .local-video-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 30%;
  height: auto;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.video-call-box .local-video-container video {
  width: 100%;
  height: 100%;
  display: block;
  margin-left: auto;
}

.end-video-call-container {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 30px;
  text-align: center;
}

.end-video-call-container span {
  display: inline-block;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.end-video-call-container svg,
.end-video-call-container img {
  width: 100%;
  height: 100%;
}

.end-video-call-container span.mute,
.end-video-call-container span.request-transcription {
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  margin-left: 10px;
}

.end-video-call-container span.transcription-span {
  margin-left: 10px;
}

.waiting-list {
  margin-left: auto;
  border-radius: 3px;

  li div .top-name {
    font-size: 0.8em;
    margin-top: -2px;
  }

  li {
    height: 60px;
  }
}

.waiting-list-count {
  height: 50px;
  height: auto;
  margin-left: auto;

  li {
    position: relative;
    display: block;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #ebedf3;
    height: 30px;
    border-radius: 3px !important;
    z-index: 1;
    font-size: 12px;
    padding-left: 10px;
    cursor: pointer;

    i {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.waiting-list-wrap {
  position: absolute;
  right: 36px;
  top: 3px;
  width: 20%;
}

.screen-control {
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  padding: 4px;
  background-color: #fff;
  right: 3px;
  top: 3px;
  border-radius: 3px;
  z-index: 1;

  img {
    display: block;
  }
}

// 08-06-2021

input[type='datetime-local'],
input[type='date'],
input[type='time'] {
  position: relative;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator,
input[type='date']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.filter_inner_row {
  padding: 10px 0 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  align-items: center;

  .col {
    padding-right: 6px;
    padding-left: 6px;
  }

  .MuiInputLabel-formControl {
    top: -15px;
  }

  .MuiInput-underline {
    margin-top: 0;

    &::before,
    &::after {
      opacity: 0;
    }
  }

  .name-filter input::placeholder {
    font-size: 12px !important;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  input[type='date'] {
    padding: 0.89rem 1rem;
    // font-size: 1rem;
    border: 1px solid #e4e6ef;
    border-radius: 0.42rem;
    color: #666;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;
  }

  a.c_btn {
    background-color: #3699ff;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
  }
}

.c_total_time {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 10px;

  label {
    color: #777;

    &:not(:first-child) {
      margin-left: 8px;
    }

    span {
      color: #222;
      font-weight: 500;
    }
  }
}

.dashboard-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.confirm-btn button {
  padding: 5px;
}

// 17-06-2021
.c_big_btn {
  font-size: 30px;
  max-width: 345px;
  width: 100%;
  height: 210px;
  line-height: normal;
  padding: 50px;
}

.c_big_btn i {
  display: block;
  font-size: 30px;
  margin-bottom: 6px;
}

// 18-06-2021
.tick_in_front.checkbox {
  position: relative;
  padding-left: 24px;

  & > span {
    position: absolute;
    left: 0;
    top: 3px;
  }
}

.video-call-box {
  height: calc(100vh - 250px);
}

@media print {
  html,
  body {
    display: none; /* hide whole page */
  }
}

.contact-modal {
  .red-title {
    color: #fd335a !important;
    font-size: 18px;
    font-weight: bold;
  }

  .h3-style {
    font-family: 'Rubik', Helvetica, Arial, Lucida, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    color: #1a3066 !important;
    line-height: 1.3em;
  }

  .text {
    font-family: 'Rubik', Helvetica, Arial, Lucida, sans-serif;
    font-size: 16px;
    line-height: 2em;
    font-weight: bold;
    color: #666;
  }
}

/*-Update Appointment Form-*/
.update_appointment {
  max-width: 950px;
  width: 100%;
  border-radius: 5px;
  margin: 50px auto;
  padding: 50px 40px;
  box-shadow: 0px 0px 12px 5px #d2d2d2;
  background: #fff;

  h3 {
    font-weight: 600;
    text-transform: capitalize;
  }

  .view_appointment_data {
    padding: 15px;
    box-shadow: 0px 0px 12px 5px #dcdcdc;
    border-radius: 5px;
    margin: 30px 0;
    background: #fff;
  }

  .view_appointment_data_item {
    border-bottom: 1px solid #ccc;
    margin: 5px 0 0;
    padding-bottom: 5px;
  }

  .view_appointment_data_item.title {
    font-weight: 600;
  }

  .user-info .row:last-child .view_appointment_data_item {
    border: none;
  }

  .form {
    padding: 15px;
    box-shadow: 0px 0px 12px 5px #dcdcdc;
    border-radius: 5px;
    margin: 30px 0;
    background: #fff;

    .col-form-label {
      font-weight: 600;
    }
  }
}

// add css document wise
.aside,
.aside-menu  {
  background-color: #16331a;
}

@media (min-width: 992px) {
  .brand-dark .brand {
    background-color: #0e2112;
  }
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #0e2112;
}

.dashboard_legend button:last-child {
  margin-right: 45px !important;
}

.filter_main_row button {
  width: 100%;
}

#kt_footer a {
  text-transform: uppercase;
}

.sn_full_height {
  height: calc(100vh - 350px);
}

.sn_left_section,
.sn_right_section {
  cursor: pointer; /*background-color: #ddffef;*/
  /*color: #4ab995;*/
  color: #000;
  transition: color 0.15s ease, background-color 0.15s ease;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.sn_content {
  text-align: center;
  font-size: 3rem;
  /*padding: 50px;*/
}

.sn_left_section,
.sn_right_section {
  cursor: pointer;
  color: #000;
  transition: color 0.15s ease, background-color 0.15s ease;
  width: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  max-height: 50vh;
}

.sn_content i.fas  {
  color: inherit;
  font-size: 4rem;
  margin-bottom: 50px;
}

.sn_content img {
  max-width: 85%;
}

/* for user list in messages  */

.users-container {
  position: relative;
  border-right: 1px solid #e6ecf3;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.users-container ul {
  list-style-type: none;
}

.users {
  padding: 0;
}

.users .person {
  position: relative;
  width: 100%;
  padding: 10px 1rem;
  cursor: pointer;
  border-bottom: 1px solid #f0f4f8;
  float: left;
}

.users .person:hover {
  background-color: #ffffff;
  background-image: -webkit-linear-gradient(right, #e9eff5, #ffffff);
}

.users .person.active-user {
  background-color: #ffffff;
  background-image: -webkit-linear-gradient(right, #f7f9fb, #ffffff);
  background-image: -moz-linear-gradient(right, #f7f9fb, #ffffff);
  background-image: -ms-linear-gradient(right, #f7f9fb, #ffffff);
  background-image: -o-linear-gradient(right, #f7f9fb, #ffffff);
}

.users .person:last-child {
  border-bottom: 0;
}

.users .person .user {
  display: inline-block;
  position: relative;
  margin-right: 10px;
}

.users .person .user img {
  width: 40px;
  height: 35px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

.users .person p.name-time {
  font-weight: 600;
  font-size: 0.85rem;
  display: inline-block;
}

.users .person p.name-time .time {
  font-weight: 400;
  font-size: 0.7rem;
  text-align: right;
  color: #8796af;
}

.chat_main_wrp .users-container .users .person .name-time span {
  font-size: 12px;
  line-height: 100%;
}

.chat_main_wrp .users-container .users .person .name-time .room-name {
  font-size: 15px;
  font-weight: 700;
}

.users .person .status {
}

/* for message TO selected user class */

.selected-user {
  padding: 8px;
  position: absolute;
  top: 15px;
  z-index: 1;
  background: #fff;
  left: 15px;
  right: 15px;
  // box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.selected-user .user img {
  width: 48px;
  height: 48px;
  object-fit: contain;
  border-radius: 100%;
  border: 1px solid #e2e2e2;
  margin-right: 10px;
}

.selected-user span {
  line-height: 100%;
  font-size: 12px;
}

.selected-user span.name {
  font-weight: 700;
  font-size: 15px;
}

/* for message text .chat-container */

.chat-container {
  position: relative;
  padding: 1rem;
}

.chat-container li.chat-left,
.chat-container li.chat-right {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 10px;
}

.chat-container li img {
  width: 48px;
  height: 48px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  object-fit: contain;
  border: 1px solid #e2e2e2;
}

.chat-container li .chat-avatar {
  margin-right: 20px;
}

.chat-container li.chat-right {
  justify-content: flex-end;
}

.chat-container li.chat-right > .chat-avatar {
  margin-left: 15px;
  margin-right: 10px;
}

.chat-container li .chat-name {
  font-size: 0.75rem;
  color: #999999;
  text-align: center;
}

.chat-container li .chat-text {
  padding: 16px 20px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  background-color: #e5edf5;
  font-weight: 500;
  position: relative;
  display: inline-block;
}

// .chat-container li.chat-left .chat-text {
//   border-radius: 0 25px 25px 30px;
//   background-color: rgb(32 168 216 / 30%);
// }

.chat-box .chat-left .chat_text_wrp {
  width: 70%;
}

.chat-box {
  padding-left: 0;
}

.chat-container li .chat-hour strong {
  font-size: 14px;
  padding-right: 10px;
  color: #23282c;
  text-transform: capitalize;
}

.chat-container li.chat-right .chat_text_wrp {
  text-align: right;
  width: 70%;
}

.chat-container li.chat-right .chat_text_wrp .chat-text {
  border-radius: 30px 0 25px 30px;
}

.chat-container li.chat-right .chat_text_wrp .chat-hour {
  justify-content: flex-end;
}

.chat_main_wrp .users-container .users {
  height: calc(100vh - 150px);
  margin-bottom: 0;
}

.chat_main_wrp .chat-container .chat-container-in {
  height: calc(100vh - 270px);
  overflow-x: hidden;
  margin-top: 90px;
}

.chat-container-bg {
  background: #eff7fe;
}

// .chat_main_wrp .users-container .users .person.active {
//   background: #eff7fe;
// }

/* .chat-container li .chat-text:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 10px;
  left: -20px;
  border: 10px solid;
  border-color: transparent #e5e7f3 transparent transparent;
} */

.chat-container li.chat-right > .chat-text {
  text-align: right;
}

.chat-container li.chat-right > .chat-text:before {
  right: -20px;
  border-color: transparent transparent transparent #e5e7f3;
  left: inherit;
}

.chat-container li .chat-hour {
  padding: 0;
  margin-bottom: 10px;
  font-size: 0.7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0px 5px 0px;
  color: grey;
}

.chat-container li.chat-right > .chat-hour {
  margin: 0 15px 0 0;
}

.chat_main_wrp .users-container .users .person {
  display: flex;
  flex-wrap: wrap;
}

.chat_main_wrp .users-container .users .person .user {
  width: 48px;
}

.chat_main_wrp .users-container .users .person .user img {
  width: 48px;
  height: 48px;
  object-fit: contain;
  border: 1px solid #e2e2e2;
}

.chat_main_wrp .users-container .users .person .name-time {
  width: calc(100% - 58px);
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.chat_main_wrp .users-container .users .person.active:before {
  content: '';
  width: 3px;
  height: 100%;
  background: #20a8d8;
  position: absolute;
  top: 0;
  left: 0;
}

.chat_main_wrp.message .users-container .users li:first-child {
  padding: 20px;
}

.chat_main_wrp .users-container .users .person .name-time span {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  align-items: center;
}

.chat_main_wrp .users-container .users .person .name-time .role_type {
  padding-top: 10px;
  color: #888888;
}

.chat_main_wrp .users-container .users .person .name-time .role_type i {
  font-size: 14px;
  margin-right: 4px;
}

/* TEXT MESSAGE ATTACH AND SEND BTNS */

.message-module button.msg-btn:not(.attachment-btn) {
  right: 9px;
}

.message-module button.msg-btn {
  height: 34px;
  width: 34px;
  top: 6px;
}

.chat_main_wrp .users-container .users li:first-child .css-yk16xz-control > div:nth-child(2) {
  display: none;
}

.custom-chat.chat-container-bg .selected-user {
  position: relative;
  margin-bottom: 30px;
  border-radius: 8px;
  background: #0d678c;
  color: #fff;
}

.custom-chat.chat-container-bg .chat-container li.chat-left .chat-text {
  border-radius: 12px 12px 12px 0;
  padding: 10px 15px;
  min-width: 100px;
}

.custom-chat.chat-container-bg .chat-container li.chat-right .chat_text_wrp .chat-text {
  padding: 10px 15px;
  min-width: 100px;
  border-radius: 12px 12px 0 12px;
  -webkit-border-radius: 12px 12px 0 12px;
  -moz-border-radius: 12px 12px 0 12px;
  -ms-border-radius: 12px 12px 0 12px;
  -o-border-radius: 12px 12px 0 12px;
}

.custom-chat.chat-container-bg li.chat-left .chat-text {
  border-radius: 0 25px 25px 30px;
  // background-color: rgb(13 103 140);
  color: #fff;
}

.custom-chat.chat-container-bg .chat-container li .chat-hour span,
.custom-chat.chat-container-bg .chat-container li .chat-hour strong {
  font-size: 10px;
}

.message-module.chat-container li.chat-left .chat-text {
  border-radius: 0 8px 8px 8px;
  padding: 5px 20px;
  background: #0d678c;
  color: #fff;
}

.message-module.chat-container li.chat-right .chat_text_wrp .chat-text {
  border-radius: 8px 0 8px 8px;
  padding: 5px 10px;
}

.message-module.chat-container li .chat-hour strong,
.message-module.chat-container li .chat-hour span {
  font-size: 12px;
}

.chat-container.message-module .chat-container-in {
  margin-bottom: 50px;
}

.chat_main_wrp .chat-container.message-module .chat-container-in {
  height: calc(100vh - 350px);
}

.message-module .sendMsg {
  position: absolute;
  bottom: 0;
  width: 95%;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-bottom: 15px;
}

.message-module .sendMsg input:focus {
  outline: none;
}

.message-module .sendMsg input {
  width: 100%;
  border: 1px solid #eee;
  border-radius: 29px;
  height: 45px;
  padding: 10px 30px;
}

.message-module button.msg-btn {
  position: absolute;
  right: 0;
  //border: 1px solid #1e1e2d;
  //background: #1e1e2d;
  height: 43px;
  width: 43px;
  border-radius: 29px;
  color: #fff;
  text-align: center;
}

.message-module button.msg-btn:focus {
  outline: none;
}

.message-module .msg-btn.attachment-btn {
  right: 50px !important;
}

/* 14-05-2021 X */

/* FOR CHAT COUNTER */

.message__counter:not(:empty) {
  position: absolute;
  right: 30px;
  top: 50%;
  background-color: #0d678c;
  color: #fff;
  padding: 2px 7px;
  line-height: normal;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

/* FOR CHAT IMAGES */

.message-module.chat-container li .chat-text img {
  background-color: #fff;
  max-width: 150px;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

/* .message-module.chat-container li .chat-text img:hover{max-width:200px;width:200px;height:200px;}*/

/* MESSAGE LEFT USER LIST STYLE */

.chat_main_wrp.message .users-container .users li:first-child {
  padding: 20px;
}

.chat_main_wrp .users-container .users .person .name-time span {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  align-items: center;
}

.chat_main_wrp .users-container .users .person .name-time .role_type {
  padding-top: 10px;
  color: #888888;
}

.chat_main_wrp .users-container .users .person .name-time .role_type i {
  font-size: 14px;
  margin-right: 4px;
}

/* TEXT MESSAGE ATTACH AND SEND BTNS */

.message-module button.msg-btn:not(.attachment-btn) {
  right: 9px;
}

.message-module button.msg-btn {
  height: 34px;
  width: 34px;
  top: 6px;
}

// 27-08-2021
.video-call-main {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  position: relative;
  height: 100%;

  .video-call-box {
  /*   max-width: 70%; */
    width: 100%;
  }

  .video-call-box.video-call-box-blackboard {
    max-width: 100%;
  }

  .video-call-box.fs {
    max-width: 100%;
  }

  .video-call-list {
    max-width: 30%;
    width: 100%;
    padding: 0 15px;

    .local-video-container {
      margin-top: 20px;

      video {
        width: 100%;
        height: auto;
      }
    }

    & > ul {
      max-height: 350px;
      overflow-y: auto;
      margin: 0;
      padding: 0;

      li {
        padding: 10px;
        font-weight: 500;
        line-height: 24px;
        font-size: 16px;
        background-color: #f5f5f5;

        &:not(:first-child) {
          margin-top: 9px;
        }
      }
    }
  }

  .video-call-list.fs{
    max-width: 20%;

    .local-video-container{
      display: flex;
    }
  }
}

.video-call-box .local-video-container {
  // position: unset;
  right: unset;
  left: 0;
}

.video-call-main .video-call-list.fs {
  position: fixed;
  z-index: 999999999;
  background-color: beige;
  padding: 0;
  bottom: 0;
  right: 0;

  ul {
    display: none;
  }

  .local-video-container {
    margin: 0;
  }
}

.video-call-main .video-call-list {
  position: absolute;
  bottom:0;
  right:0;
  height: auto;
  padding-bottom: 300px;
}

.video-call-main .video-call-list .local-video-container {
  margin: 0;
  position: absolute;
  bottom: 10%;
}

.black-color {
  background-color: #000;
}

.msg-notification {
  display: flex;
  align-items: center;
}

.transcriptions-container {
  transform: translateX(30%);
  max-width: 50%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index:1;
}
.transcriptions-container-fullscreen{
  z-index: 999999999;
  position: absolute;
  padding: 5px;
  margin: 0;
  bottom: 140px;
  width:25.3%;
  transform: translateX(147.6%);
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.transcription-text {
  color: white;
  font-size: 1rem;
  padding-left: 4px;
  height: 3em;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  text-align: left;
  margin-bottom: 10px;
}

.interpreter-transcription {
  color: #dc143c;
}

.transcription{
  overflow: hidden;
}

// 19-11-2021 X

// .chat_admin_main_wrp {
//   .users-container {
//     .users {
//       .interpreter {
//         // background-color: #4a99ff6b;
//         background-color: #3699ff8a;
//         &:hover {
//           background-color: #62affc;
//           background-image: unset;
//         }
//         .room-name {
//           // color: #3F4254;
//         }
//         .role_type {
//           color: #3f4254 !important;
//           opacity: 0.7;
//         }
//       }
//       li {
//         &:not(:first-child) {
//           margin-top: 1.5px;
//         }
//         &.active {
//           background-color: #3699ff;
//           &::before {
//             background-color: #096ccf !important;
//             width: 6px !important;
//           }
//           .room-name {
//             color: #fff;
//           }
//           .role_type {
//             color: #fff !important;
//             opacity: 0.7;
//           }
//         }

//         // &.active{
//         //   background-color: rgb(104, 124, 252);
//         //   &::before{
//         //     background-color: rgb(60, 82, 224) !important;
//         //   }
//         //   .room-name{
//         //     color: #fff;
//         //   }
//         //   .role_type{
//         //     color: #fff !important;
//         //     opacity: 0.9;
//         //   }
//         //   &:hover{
//         //     background-color: rgba(104, 124, 252, 0.795);
//         //     background-image: unset;
//         //   }
//         // }
//       }
//     }
//   }
// }

// 24-11-2021 X

.chat_admin_main_wrp {
  .users {
    .person {
      position: relative;
      background-color: #fff;
      margin-left: 0;
      margin-right: 0;

      &.active,
      &:hover {
        background-color: #eff7fe;
        background-image: unset;

        &::before {
          background-color: #4799ff;
        }
      }

      &::before {
        position: absolute;
        content: '';
        width: 4px;
        left: 0;
        height: 100%;
        top: 0;
      }

      &.interpreter {
        padding-right: 2.3rem;

        &::after {
          position: absolute;
          content: '\f4fc';
          top: 20px;
          right: 10px;
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
        }
      }

      .status {
        width: 10px;
        height: 10px;
        border-radius: 100px;
        position: absolute;
        background: #3699ff;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .users-container {
    min-height: calc(100vh - 230px);
    max-height: calc(100vh - 230px);
    overflow: auto;

    ul {
      margin: 0;
      padding: 0;

      &::-webkit-scrollbar {
        width: 3px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #d8d8d8;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #999999;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #777777;
      }
    }
  }

  .selected-user {
    position: unset;
    margin: 10px auto 0;
    width: 96%;
  }

  .chat-container-in {
    margin: 0 !important;
    min-height: calc(100vh - 380px);
    max-height: calc(100vh - 380px);
    padding-bottom: 50px;
    overflow: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #d8d8d8;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #999999;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #777777;
    }

    .sendMsg {
      bottom: -50px;
    }
  }

  .chat-box {
    max-height: inherit;
    margin: 0;
    padding-right: 10px;
  }
}

// RESPONSIVE

@media screen and (max-width: 1930px) {
  .end-video-call-container {
    bottom: 90px;
  }
}

@media screen and (max-width: 1450px) {
  .video-call-main .video-call-list .local-video-container {
    margin: 0;
    position: absolute;
    bottom: 3%;
  }
  .video-call-main .video-call-list > ul {
    max-height: 260px;
  }
}

@media screen and (max-width: 1370px) {
  .video-call-main .video-call-list .local-video-container {
    margin: 0;
    position: absolute;
    bottom: 6%;
  }
  .video-call-main .video-call-list > ul {
    max-height: 240px;
  }
  .end-video-call-container {
    bottom: 50px;
  }
}

@media screen and (max-width: 480px) {
  .col-8,
  .chat-container-bg {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .chat_main_wrp .users-container .users {
    height: calc(50vh - 150px);
  }
  .chat_main_wrp .chat-container.message-module .chat-container-in {
    height: auto;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .selected-user {
    position: unset;
  }

  .chat-container-bg {
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-top: 30px;
    padding-top: 15px;
  }
  .chat-container li .chat-hour {
    justify-content: space-between;
    width: 100%;
  }

  .chat-box .chat-left .chat_text_wrp {
    width: 100%;
  }
  .chat-box {
    max-height: 160px;
    margin-bottom: 20px;
  }
}

.appuntamento_video_wrapper p {
  text-align: center;
}

.appuntamento_video_wrapper iframe {
  // width: 50%;
  width: 100%;
  height: 300px;
  border-radius: 10px;
  // padding: 10px;
}

.fnt-videocall {
  font-size: 20px;
}

@media screen and (max-width: 480px) {
  .appuntamento_video_wrapper iframe {
    height: 250px;
    // width: 100%;
    // padding: 0px;
  }
}

// 04-02-2022 X
.appuntamento-modal-body {
  min-height: 140px;
}

.c-loader {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 5px solid #3699ff;
  border-radius: 100px;
  border-right-color: transparent;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation-name: loader;
  animation-direction: normal;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: inherit;
}

@keyframes loader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.display-none {
  display: none;
}

.container-new {
  width: 100%;
  padding: 0 get($page-padding, desktop);

}

.login-form.login-signin input[type=email] {
  max-width: 89%;
}

.MuiOutlinedInput-input {
  padding: 0 !important;
}

.sn_content {
  padding: 20px;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  font-size: 1.5rem;
  margin-top: 30px;
  margin-bottom: 30px;

}

.sn_content:hover {
  background-color: #f5f5f5;
}

// bydefault kevu rakhvanu chhe? saru lage tevu
// but data j nahi toh etle data nai aave olny progressbar j aavase


//NEW CLASSES BLACKBOARD

.blackboard-container {
  display: none;
}

.blackboard-container.show {
  display: block;
}

.blackboard-header {
  padding-top: 12px;
}

.blackboard-body {
 // padding: 10px;
}

.close-icon {
  cursor: pointer;
}

.blackboard {
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  margin-left: 10px;
}

.blackboard-div{
  background-color: #d9d6d6;
}
